import { Typography } from "@mui/material";
import clsx from "clsx";
import RightSizingIcon from "../../Icons/RightSizingIcon";
import UpAndDownCircleIcon from "../../Icons/UpAndDownCircleIcon";
import SavingsRow from "../SavingsRow";
import Tooltip from "../Tooltip";

const ICON_SIZE = 14;

interface Props {
  hasHPA: boolean;
  rightSizingSavings: React.ReactNode;
  HPASavings: React.ReactNode;
  overallSavings: React.ReactNode;
}

const HPASavingsAvailable = ({ hasHPA, rightSizingSavings, HPASavings, overallSavings }: Props) => {
  if (!hasHPA) {
    return null;
  }

  return (
    <Tooltip
      maxWidth={500}
      className="relative"
      title={
        <div className="flex flex-col gap-1">
          <SavingsRow label="HPA" value={HPASavings} icon={<UpAndDownCircleIcon />} />
          <SavingsRow label="RightSize" value={rightSizingSavings} icon={<RightSizingIcon />} />
          <hr className="w-[17.6875rem] border-text-lightBlack" />
          <SavingsRow
            label="Savings available"
            value={overallSavings}
            className="text-guideline-darkGreen"
            style={{
              fontWeight: 700,
            }}
          />
        </div>
      }
    >
      <Typography
        variant="body2"
        className={clsx("fullCellTooltipContent relative", {
          "text-guideline-darkGreen": overallSavings !== "$0",
        })}
        fontWeight={500}
      >
        {overallSavings}
      </Typography>
      <UpAndDownCircleIcon className="absolute right-[-18px] top-[2px]" width={ICON_SIZE} height={ICON_SIZE} />
    </Tooltip>
  );
};

export default HPASavingsAvailable;
