import { Typography } from "@mui/material";
import CreativeInfoIcon from "../../../Icons/CreativeInfoIcon";
import { GetPolicyTuningHpaCurrentDataResponse } from "../../../api/fetcher";
import Tooltip from "../../../components/Tooltip";
import { camelCaseToSnakeSpace } from "../../../utils/formatterUtils";
import { METRIC_BOX_CLASS_NAME } from "./utils";

const ICON_SIZE = 12;
const MAX_TOOLTIP_WIDTH = 500;
const REPLICAS = "replicas";
const BOX_CLASS_WRAPPER = `${METRIC_BOX_CLASS_NAME} flex items-center justify-center gap-1`;

const PercentileBox = ({ percentile }: { percentile: number }) => {
  const percentileDisplayValue: string =
    percentile >= 100 ? "the maximum replicas" : `to window P${Math.round(percentile)}`;

  return (
    <Tooltip
      title={
        <Typography variant="caption">
          <b>max over window</b>
          <p className="italic">HPA optimization could not be achieved</p>
          <p>
            <span className="underline">time window:</span>
            <span className="italic"> {percentileDisplayValue}</span>.
          </p>
        </Typography>
      }
      placement="left"
      maxWidth={MAX_TOOLTIP_WIDTH}
    >
      <div className={BOX_CLASS_WRAPPER}>
        Optimization method
        <div className="w-[12px]">
          <CreativeInfoIcon width={ICON_SIZE} height={ICON_SIZE} />
        </div>
      </div>
    </Tooltip>
  );
};

const OptimizationGapBox = ({ reason }: { reason: string }) => (
  <Tooltip
    title={
      <Typography variant="caption">
        <b>Optimization gap</b>
        <p className="italic">HPA optimization could not be achieved</p>
        <p>
          Workload has optimization gap due to: <span className="italic">{camelCaseToSnakeSpace(reason)}</span>.
        </p>
      </Typography>
    }
    placement="left"
    maxWidth={MAX_TOOLTIP_WIDTH}
  >
    <div className={BOX_CLASS_WRAPPER}>
      Optimization gap
      <div className="w-[12px]">
        <CreativeInfoIcon width={ICON_SIZE} height={ICON_SIZE} />
      </div>
    </div>
  </Tooltip>
);

interface Props {
  data: GetPolicyTuningHpaCurrentDataResponse | undefined;
}

const PredictableBadges = ({ data }: Props) => {
  if (!data || (data?.optimizationStrategy !== REPLICAS && !data?.percentile && !data?.optimizationGap)) return null;

  return (
    <>
      {data?.optimizationStrategy && data?.percentile && data.optimizationStrategy === REPLICAS && (
        <PercentileBox percentile={data.percentile} />
      )}
      {data?.optimizationGap && <OptimizationGapBox reason={data?.optimizationGap ?? "tempMessage"} />}
    </>
  );
};

export default PredictableBadges;
