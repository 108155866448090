import { CircularProgress, Typography } from "@mui/material";
import clsx from "clsx";
import { useEffect, useState } from "react";
import Button, { BUTTON_VARIANTS } from "../../../components/Button";
import UpAndDownCircleIcon from "../../../Icons/UpAndDownCircleIcon";
import { LIGHT_PURPLE_BOX_CLASS_NAME } from "../../../utils/styleUtils";
import useGetDefaultHPAPolicy from "./hooks/useGetDefaultHPAPolicy";
import useIsPolicyHPAEnabled from "./hooks/useIsPolicyHPAEnabled";
import { Policy } from "./utils";

const ICON_SIZE = 14;

interface Props {
  selectedPolicy: Policy | undefined;
  setSelectedPolicy: (policy: Policy | undefined) => void;
}

const SwitchToHPA = ({ selectedPolicy, setSelectedPolicy }: Props) => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const defaultHPAPolicy = useGetDefaultHPAPolicy();
  const isPolicyHPAEnabled = useIsPolicyHPAEnabled({ policyName: selectedPolicy?.name });

  useEffect(() => {
    setIsLoading(false);
  }, [selectedPolicy]);

  if (!defaultHPAPolicy || isPolicyHPAEnabled) return null;

  return (
    <div className={clsx(LIGHT_PURPLE_BOX_CLASS_NAME, "relative flex items-center")}>
      <Typography variant="body2" className="flex items-center gap-1">
        <UpAndDownCircleIcon width={ICON_SIZE} height={ICON_SIZE} />
        <span>
          The chart below is simulating <b>{defaultHPAPolicy}</b> policy
        </span>
      </Typography>
      <Button
        onClick={() => {
          setIsLoading(true);
          setSelectedPolicy({ name: defaultHPAPolicy, displayName: defaultHPAPolicy });
        }}
        variant={BUTTON_VARIANTS.mediumDarkPurple}
        label={`SWITCH TO ${defaultHPAPolicy?.toUpperCase()} POLICY`}
        className="w-fit"
        fontSize={14}
      />
      {isLoading && (
        <div className="w-full h-full absolute top-0 left-0 rounded-lg flex items-center justify-center bg-background-opacityBackground">
          <CircularProgress />
        </div>
      )}
    </div>
  );
};
export default SwitchToHPA;
