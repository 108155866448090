import { useEffect, useState } from "react";
import { StringParam, useQueryParam } from "use-query-params";
import { components } from "../../api/schema";
import { DataGrid, GridColDef, GridRenderCellParams } from "@mui/x-data-grid";
import Tooltip from "../Tooltip";
import { getDataGridSx } from "../../utils/styleUtils";
import { DIFF_DEFAULT_PROPS, formatXDigits, SimpleEllipsisWithTooltip } from "./Utils";
import UsageAndRequestChart, { Elements } from "./UsageAndRequestChart";
import SearchNodeFilter from "./SearchNodeFilter";
import MultiSelect from "../MultiSelect";
import CustomColumnsFilterButton from "../CustomColumnsFilterButton";
import ExportCSV, { HAS_EXPORT_TABLE_AS_CSV } from "../exportCSV/ExportCSV";
import CustomHeader from "./CustomHeader";

type CSVExportType = NodeGroupRowEntry & { id: string };

enum Columns {
  Name = "Name",
  Cost = "Monthly Cost",
  InstanceTypes = "Instance Types",
  MinCurrentMax = "Min/Current/Max",
  CpuRequestVsAllocatableDiff = "CPU (Request vs Allocatable)",
  CpuUsageVsAllocatableDiff = "CPU (Usage vs Allocatable)",
  MemoryRequestVsAllocatableDiff = "Memory (Request vs Allocatable)",
  MemoryUsageVsAllocatableDiff = "Memory (Usage vs Allocatable)",
}

const COLUMNS_MENU_OPTIONS = [
  Columns.Cost,
  Columns.InstanceTypes,
  Columns.MinCurrentMax,
  Columns.CpuRequestVsAllocatableDiff,
  Columns.CpuUsageVsAllocatableDiff,
  Columns.MemoryRequestVsAllocatableDiff,
  Columns.MemoryUsageVsAllocatableDiff,
];

export type NodeGroupRowEntry = {
  name: string;
  instanceTypes: string[];
  instanceTypesDisplayName: string;
  cpuDiff: number;
  memoryDiff: number;
  numNodes: { min: number; current: number; max: number };
  numNodesDisplayName: string;
  cpuRequests: number;
  cpuAllocatable: number;
  cpuUsage: number;
  memoryRequests: number;
  memoryAllocatable: number;
  memoryUsage: number;
  cost: number;
};

type NodeGroupResponseTypeSchema = components["schemas"]["UtilsNodeGroupInfo"];

const renderNameCell = (params: GridRenderCellParams<string, NodeGroupRowEntry, string>) => {
  return <SimpleEllipsisWithTooltip text={params.row.name} />;
};

const renderCostCell = (params: GridRenderCellParams<string, NodeGroupRowEntry, string>) => {
  return <p>${formatXDigits(params.row.cost)}</p>;
};

const renderInstanceTypesCell = (params: GridRenderCellParams<string, NodeGroupRowEntry, string>) => {
  return <SimpleEllipsisWithTooltip text={params.row.instanceTypes.join(", ")} />;
};

const renderNumNodesCell = (params: GridRenderCellParams<string, NodeGroupRowEntry, string>) => {
  const maxSizeText = params.row.numNodes.max === -1 ? "-" : params.row.numNodes.max;
  const minSizeText = params.row.numNodes.min === -1 ? "-" : params.row.numNodes.min;
  const maxSizeTooltip = maxSizeText === "-" ? "unknown" : maxSizeText;
  const minSizeTooltip = minSizeText === "-" ? "unknown" : minSizeText;
  const tooltip = `Min: ${minSizeTooltip}, Current: ${params.row.numNodes.current}, Max: ${maxSizeTooltip}`;
  const text = `${minSizeText}/${params.row.numNodes.current}/${maxSizeText}`;
  return (
    <Tooltip title={tooltip}>
      <p style={{ textOverflow: "ellipsis", whiteSpace: "nowrap", overflow: "hidden" }}>{text}</p>
    </Tooltip>
  );
};

const getColumns = (selectedColumns: Columns[]): GridColDef[] => [
  {
    field: "name",
    headerName: "Name",
    width: 300,
    minWidth: 150,
    flex: 1,
    type: "string",
    align: "center",
    disableColumnMenu: true,
    sortable: true,
    renderCell: renderNameCell,
  },
  {
    field: "cost",
    hide: !selectedColumns.includes(Columns.Cost),
    headerName: "Monthly Cost",
    description: "Monthly Cost",
    flex: 1,
    minWidth: 150,
    type: "number",
    align: "center",
    disableColumnMenu: true,
    sortable: true,
    renderCell: renderCostCell,
  },
  {
    field: "instanceTypesDisplayName",
    hide: !selectedColumns.includes(Columns.InstanceTypes),
    headerName: "Instance Types",
    description: "Instance Types",
    flex: 1,
    minWidth: 150,
    type: "string",
    align: "center",
    disableColumnMenu: true,
    sortable: true,
    renderCell: renderInstanceTypesCell,
  },
  {
    field: "numNodes",
    hide: !selectedColumns.includes(Columns.MinCurrentMax),
    headerName: "Min/Current/Max",
    description: "Min/Current/Max number of nodes in the node group",
    flex: 1,
    minWidth: 150,
    align: "center",
    disableColumnMenu: true,
    sortable: false,
    renderCell: renderNumNodesCell,
  },
  {
    ...DIFF_DEFAULT_PROPS,
    ...{
      field: "cpuRequestVsAllocatableDiff",
      headerName: "cpuRequestVsAllocatableDiff",
      hide: !selectedColumns.includes(Columns.CpuRequestVsAllocatableDiff),
      renderHeader: () => <CustomHeader title="CPU Request" tooltipContent="Request vs Allocatable" />,
      renderCell: (params: GridRenderCellParams<string, NodeGroupRowEntry, string>) => {
        if (params.row.cpuAllocatable === 0) {
          return null;
        }
        return (
          <UsageAndRequestChart
            usage={Math.round((params.row.cpuUsage / params.row.cpuAllocatable) * 100)}
            request={Math.round((params.row.cpuRequests / params.row.cpuAllocatable) * 100)}
            tooltipData={{
              usage: params.row.cpuUsage,
              request: params.row.cpuRequests,
              allocatable: params.row.cpuAllocatable,
            }}
            elementToDisplay={[Elements.Request]}
            showMetricsTitles={false}
            showAllocatableBellow
          />
        );
      },
    },
  },
  {
    ...DIFF_DEFAULT_PROPS,
    ...{
      field: "cpuUsageVsAllocatableDiff",
      headerName: "cpuUsageVsAllocatableDiff",
      hide: !selectedColumns.includes(Columns.CpuUsageVsAllocatableDiff),
      renderHeader: () => <CustomHeader title="CPU Usage" tooltipContent="Usage vs Allocatable" />,
      renderCell: (params: GridRenderCellParams<string, NodeGroupRowEntry, string>) => {
        if (params.row.cpuAllocatable === 0) {
          return null;
        }
        return (
          <UsageAndRequestChart
            usage={Math.round((params.row.cpuUsage / params.row.cpuAllocatable) * 100)}
            request={Math.round((params.row.cpuRequests / params.row.cpuAllocatable) * 100)}
            tooltipData={{
              usage: params.row.cpuUsage,
              request: params.row.cpuRequests,
              allocatable: params.row.cpuAllocatable,
            }}
            elementToDisplay={[Elements.Usage]}
            showMetricsTitles={false}
            showAllocatableBellow
          />
        );
      },
    },
  },
  {
    ...DIFF_DEFAULT_PROPS,
    ...{
      field: "memoryRequestVsAllocatableDiff",
      headerName: "memoryRequestVsAllocatableDiff",
      hide: !selectedColumns.includes(Columns.MemoryRequestVsAllocatableDiff),
      renderHeader: () => <CustomHeader title="Memory Request" tooltipContent="Request vs Allocatable" />,
      renderCell: (params: GridRenderCellParams<string, NodeGroupRowEntry, string>) => {
        if (params.row.memoryAllocatable === 0) {
          return null;
        }
        return (
          <UsageAndRequestChart
            usage={Math.round((params.row.memoryUsage / params.row.memoryAllocatable) * 100)}
            request={Math.round((params.row.memoryRequests / params.row.memoryAllocatable) * 100)}
            tooltipData={{
              usage: params.row.memoryUsage,
              request: params.row.memoryRequests,
              allocatable: params.row.memoryAllocatable,
            }}
            elementToDisplay={[Elements.Request]}
            showMetricsTitles={false}
            showAllocatableBellow
          />
        );
      },
    },
  },
  {
    ...DIFF_DEFAULT_PROPS,
    ...{
      field: "memoryUsageVsAllocatableDiff",
      headerName: "memoryUsageVsAllocatableDiff",
      hide: !selectedColumns.includes(Columns.MemoryUsageVsAllocatableDiff),
      ...DIFF_DEFAULT_PROPS,
      ...{
        renderHeader: () => <CustomHeader title="Memory Usage" tooltipContent="Usage vs Allocatable" />,
        renderCell: (params: GridRenderCellParams<string, NodeGroupRowEntry, string>) => {
          if (params.row.memoryAllocatable === 0) {
            return null;
          }
          return (
            <UsageAndRequestChart
              usage={Math.round((params.row.memoryUsage / params.row.memoryAllocatable) * 100)}
              request={Math.round((params.row.memoryRequests / params.row.memoryAllocatable) * 100)}
              tooltipData={{
                usage: params.row.memoryUsage,
                request: params.row.memoryRequests,
                allocatable: params.row.memoryAllocatable,
              }}
              elementToDisplay={[Elements.Usage]}
              showMetricsTitles={false}
              showAllocatableBellow
            />
          );
        },
      },
    },
  },
];

interface Props {
  nodeGroups: NodeGroupResponseTypeSchema[];
  isLoading: boolean;
}

const parseIntoRows = (nodeGroups: NodeGroupResponseTypeSchema[]): NodeGroupRowEntry[] => {
  const enhancedNodeGroups: Map<string, NodeGroupRowEntry> = new Map();
  nodeGroups.forEach((nodeGroup: NodeGroupResponseTypeSchema) => {
    enhancedNodeGroups.set(nodeGroup.name, {
      name: nodeGroup.name,
      instanceTypes: nodeGroup.instanceTypes,
      instanceTypesDisplayName: "",
      numNodes: { min: nodeGroup.minSize, current: nodeGroup.currentSize, max: nodeGroup.maxSize },
      numNodesDisplayName: `${nodeGroup.minSize}/${nodeGroup.currentSize}/${nodeGroup.maxSize}`,
      cpuRequests: nodeGroup.cpuRequest,
      memoryRequests: nodeGroup.memoryRequest,
      cpuAllocatable: nodeGroup.cpuAllocatable,
      cpuUsage: nodeGroup.cpuUsage,
      cpuDiff: 0,
      memoryDiff: 0,
      memoryAllocatable: nodeGroup.memoryAllocatable,
      memoryUsage: nodeGroup.memoryUsage,
      cost: nodeGroup.cost,
    });
  });

  return Array.from(enhancedNodeGroups.values());
};

function NodeGroupsTab({ nodeGroups, isLoading }: Props) {
  const [rows, setRows] = useState<NodeGroupRowEntry[]>([]);
  const [searchTerm] = useQueryParam("setSearchTerm", StringParam);
  const [selectedColumns, setSelectedColumns] = useState<Columns[]>([
    Columns.Cost,
    Columns.InstanceTypes,
    Columns.MinCurrentMax,
    Columns.CpuRequestVsAllocatableDiff,
    Columns.CpuUsageVsAllocatableDiff,
    Columns.MemoryRequestVsAllocatableDiff,
    Columns.MemoryUsageVsAllocatableDiff,
  ]);

  useEffect(() => {
    let rowsToDisplay = parseIntoRows(nodeGroups);

    if (searchTerm && searchTerm.length > 0) {
      rowsToDisplay = rowsToDisplay.filter((row) => row.name.includes(searchTerm));
    }

    rowsToDisplay = rowsToDisplay.sort((a, b) => a.name.localeCompare(b.name));

    setRows(rowsToDisplay);
  }, [nodeGroups, searchTerm]);

  return (
    <div>
      <div className="w-full flex pb-8">
        <div className="flex-grow">
          <SearchNodeFilter />
        </div>
        <div>
          <MultiSelect
            selected={selectedColumns}
            setSelected={setSelectedColumns as React.Dispatch<React.SetStateAction<(string | undefined)[]>>}
            options={COLUMNS_MENU_OPTIONS}
            className="w-[85px]"
            customIcon={<CustomColumnsFilterButton isFiltered={selectedColumns.length > 0} />}
          />
        </div>
      </div>
      <DataGrid
        sx={{
          ...getDataGridSx(),
        }}
        rows={rows}
        columns={getColumns(selectedColumns)}
        getRowId={(row: NodeGroupRowEntry) => row.name}
        autoHeight={true}
        rowHeight={65}
        pageSize={10}
        loading={isLoading}
        disableSelectionOnClick
        getEstimatedRowHeight={() => 100}
      />
      {HAS_EXPORT_TABLE_AS_CSV && (
        <div className="mt-[-35px] ml-[10px] z-50 relative w-fit">
          <ExportCSV<CSVExportType>
            filename="node_groups.csv"
            columns={[
              "name",
              "cost",
              "instanceTypesDisplayName",
              "numNodesDisplayName",
              "cpuDiff",
              "memoryDiff",
              "cpuRequests",
              "cpuAllocatable",
              "cpuUsage",
              "memoryRequests",
              "memoryAllocatable",
              "memoryUsage",
            ]}
            columnsToRound={["cost"]}
            data={
              rows.map((row) => {
                return { ...row, id: row.name };
              }) as CSVExportType[]
            }
            columnsToSum={[
              "cost",
              "cpuDiff",
              "memoryDiff",
              "cpuRequests",
              "cpuAllocatable",
              "cpuUsage",
              "memoryRequests",
              "memoryAllocatable",
              "memoryUsage",
            ]}
            customColumnNames={{
              name: "Name",
              cost: "Monthly Cost",
              instanceTypesDisplayName: "Instance Types",
              numNodes: "Min/Current/Max",
              cpuDiff: "CPU",
              memoryDiff: "Memory",
              cpuRequests: "CPU Requests",
              cpuAllocatable: "CPU Allocatable",
              cpuUsage: "CPU Usage",
              memoryRequests: "Memory Requests",
              memoryAllocatable: "Memory Allocatable",
              memoryUsage: "Memory Usage",
            }}
          />
        </div>
      )}
    </div>
  );
}

export default NodeGroupsTab;
