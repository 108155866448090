import clsx from "clsx";
import prettyBytes from "pretty-bytes";
import { GetDashboardByNamespaceResponse } from "../../api/fetcher";
import { currencyFormatter, customNumberFormatter } from "../../utils/formatterUtils";
import Tooltip from "../Tooltip";
import { Typography } from "@mui/material";
import React from "react";

// @barel22 the new utils currently only used as part of the cost page and should be added to the workload overview‚

/** ** ** ** ** ** ** ** ** ** ** ** ** ** ** ** ** ** ** ** ** ** ** ** ** ** ** **
 *
 * General costs
 *
 ** ** ** ** ** ** ** ** ** ** ** ** ** ** ** ** ** ** ** ** ** ** ** ** ** ** ** **/
export const SCALEOPS_ROLLOUT = "scaleops-rollout";
export const ASC = "asc";
export const DESC = "desc";

/** ** ** ** ** ** ** ** ** ** ** ** ** ** ** ** ** ** ** ** ** ** ** ** ** ** ** **
 *
 * General enums, types and interfaces
 *
 ** ** ** ** ** ** ** ** ** ** ** ** ** ** ** ** ** ** ** ** ** ** ** ** ** ** ** **/

export enum WorkloadType {
  AlgoRollout = "argo-rollout",
  GenericType = "Custom Workload",
  Family = "Family",
}

/** ** ** ** ** ** ** ** ** ** ** ** ** ** ** ** ** ** ** ** ** ** ** ** ** ** ** **
 *
 * Get the cluster name row
 *
 ** ** ** ** ** ** ** ** ** ** ** ** ** ** ** ** ** ** ** ** ** ** ** ** ** ** ** **/

interface GetClusterNameRowProps {
  clusterName: string;
}

export const getClusterNameRow = ({ clusterName }: GetClusterNameRowProps) => {
  return (
    <Tooltip title={<div>{clusterName}</div>} maxWidth={500} className="truncate">
      {clusterName}
    </Tooltip>
  );
};

/** ** ** ** ** ** ** ** ** ** ** ** ** ** ** ** ** ** ** ** ** ** ** ** ** ** ** **
 *
 * Get cost row
 *
 ** ** ** ** ** ** ** ** ** ** ** ** ** ** ** ** ** ** ** ** ** ** ** ** ** ** ** **/

interface GetCostRowProps {
  cost?: number;
  colorBySavings?: boolean;
}

export const getCostRow = ({ cost, colorBySavings }: GetCostRowProps) => {
  const displayValue = currencyFormatter(cost && cost > 0 ? cost : 0);
  return (
    <span
      className={clsx({
        "text-guideline-darkGreen": colorBySavings && displayValue !== "$0" && cost && cost > -1,
      })}
    >
      {displayValue}
    </span>
  );
};

/** ** ** ** ** ** ** ** ** ** ** ** ** ** ** ** ** ** ** ** ** ** ** ** ** ** ** **
 *
 * Get percentage row
 *
 ** ** ** ** ** ** ** ** ** ** ** ** ** ** ** ** ** ** ** ** ** ** ** ** ** ** ** **/

interface GetPercentageRowProps {
  percentage?: number;
}

export const getPercentageRow = ({ percentage }: GetPercentageRowProps) => {
  percentage = !percentage || percentage > 100 || percentage < 0 ? 0 : percentage;
  const displayValue = Math.round((percentage ?? 0) * 100) / 100;
  return <span>{displayValue}%</span>;
};

/** ** ** ** ** ** ** ** ** ** ** ** ** ** ** ** ** ** ** ** ** ** ** ** ** ** ** **
 *
 * get Number Row percentage row
 *
 ** ** ** ** ** ** ** ** ** ** ** ** ** ** ** ** ** ** ** ** ** ** ** ** ** ** ** **/

interface GetNumberRowProps {
  value?: number;
}

export const getNumberRow = ({ value }: GetNumberRowProps) => {
  return <span>{customNumberFormatter(value ?? 0)}</span>;
};

/** ** ** ** ** ** ** ** ** ** ** ** ** ** ** ** ** ** ** ** ** ** ** ** ** ** ** **
 *
 * get Memory row
 *
 ** ** ** ** ** ** ** ** ** ** ** ** ** ** ** ** ** ** ** ** ** ** ** ** ** ** ** **/

interface GetMemoryRowProps {
  memory?: number;
}

export const getMemoryRow = ({ memory }: GetMemoryRowProps) => {
  const displayValue = prettyBytes(memory || 0.0, {
    bits: false,
    binary: true,
  });
  return <span>{displayValue}</span>;
};

export const CantEditScaleopsWorkloadsTooltip = (
  <p>
    <b>scaleops-system</b> workloads are excluded from automation.
  </p>
);

/** ** ** ** ** ** ** ** ** ** ** ** ** ** ** ** ** ** ** ** ** ** ** ** ***
 *
 * Select rows on loading query param types
 *
 ** ** ** ** ** ** ** ** ** ** ** ** ** ** ** ** ** ** ** ** ** ** ** ** ***/
export enum SelectRowsOnInitialLoading {
  All = "all",
  Unautomated = "unautomated",
}

/** ** ** ** ** ** ** ** ** ** ** ** ** ** ** ** ** ** ** ** ** ** ** ** ***
 *
 * Filter workloads ids from excludedFromAutomation and scaleops-system
 *
 ** ** ** ** ** ** ** ** ** ** ** ** ** ** ** ** ** ** ** ** ** ** ** ** ***/
export const workloadRowsExceptExcludedAndScaleOpsIds = (data?: GetDashboardByNamespaceResponse) =>
  (
    data?.workloads?.filter(
      (workload) => !workload.isAutomationExcluded && !workload.id.includes("/scaleops-system/")
    ) || []
  ).map((workload) => workload.id);

export enum SubHeaderType {
  Monthly = "monthly",
  Average = "average",
}

export const getWithSubHeader = (type: SubHeaderType, header?: string) => {
  return (
    <Typography
      variant="body2"
      fontWeight={700}
      sx={{ whiteSpace: "normal ", wordBreak: "break-word" }}
      className={"flex flex-col items-center gap-[2px]"}
    >
      <span className={"text-center"}>{header}</span>
      <span className="text-text-darkGray text-[.625rem]">({type})</span>
    </Typography>
  );
};
