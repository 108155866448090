import { GridAlignment, GridColDef, GridRenderCellParams, GridSortDirection } from "@mui/x-data-grid";
import { components } from "../../../../api/schema";
import { DESC, getNumberRow } from "../../../../components/WorkloadStatusByNamespace/utils";
import WorkloadNameRow from "../../../../components/WorkloadStatusByNamespace/WorkloadNameRow";
import { getCostRow, getDataRow } from "../../../Cost/Tables/NetworkWorkloads/utils";
import { PolicyTuningTabs } from "../utils";

const COST_COlUMN_MIN_WIDTH = 175;

export type ROW = components["schemas"]["WorkloadsDestinationWorkload"] & { id: string; destination: string };

type PARAMS = GridRenderCellParams<ROW, ROW>;

export const INITIAL_SORT_MODEL = [
  {
    field: String("totalCost"),
    sort: DESC as GridSortDirection,
  },
];

export const getNumericValue = (value: number | string | null | undefined) => Number(!Number.isNaN(value) ? value : 0);

const SHARED_COLUMNS_PROPS = {
  flex: 1,
  minWidth: COST_COlUMN_MIN_WIDTH,
  type: "number",
  align: "center" as GridAlignment,
  headerAlign: "center" as GridAlignment,
  disableColumnMenu: true,
  sortable: true,
};

export const getColumns = (
  setSelectedWorkload: (workload: components["schemas"]["UtilsWorkload"] | undefined) => void,
  currentCluster: string
): GridColDef[] => [
  {
    field: "WorkloadType",
    headerName: "Workload",
    flex: 3,
    minWidth: 150,
    type: "string",
    align: "left",
    disableColumnMenu: true,
    sortable: true,
    renderCell: (params: PARAMS) => (
      <WorkloadNameRow
        name={String(params.row.Name)}
        namespace={String(params.row.Namespace)}
        type={String(params.row.WorkloadType)}
        cluster={currentCluster}
        setSelectedWorkload={setSelectedWorkload}
        targetTab={PolicyTuningTabs.Network}
      />
    ),
  },
  {
    field: "totalCost",
    headerName: "Total Cost",
    ...SHARED_COLUMNS_PROPS,
    renderCell: (params: PARAMS) =>
      getCostRow({
        cost: params.row.totalCost,
      }),
  },
  {
    field: "crossAZCost",
    headerName: "Cross-AZ Cost",
    ...SHARED_COLUMNS_PROPS,
    renderCell: (params: PARAMS) =>
      getCostRow({
        cost: params.row.crossAZCost,
      }),
  },
  {
    field: "intraAZCost",
    headerName: "Intra-AZ Cost",
    ...SHARED_COLUMNS_PROPS,
    renderCell: (params: PARAMS) =>
      getCostRow({
        cost: params.row.intraAZCost,
      }),
  },
  {
    field: "totalTraffic",
    headerName: "Total Traffic",
    ...SHARED_COLUMNS_PROPS,
    renderCell: (params: PARAMS) =>
      getDataRow({
        value: params.row.totalTraffic,
      }),
  },
  {
    field: "crossAZTraffic",
    headerName: "Cross-AZ Traffic",
    ...SHARED_COLUMNS_PROPS,
    renderCell: (params: PARAMS) =>
      getDataRow({
        value: params.row.crossAZTraffic,
      }),
  },
  {
    field: "intraAZTraffic",
    headerName: "Intra-AZ Traffic",
    ...SHARED_COLUMNS_PROPS,
    renderCell: (params: PARAMS) =>
      getDataRow({
        value: params.row.intraAZTraffic,
      }),
  },
  {
    field: "replicas",
    headerName: "Replicas",
    ...SHARED_COLUMNS_PROPS,
    renderCell: (params: PARAMS) =>
      getNumberRow({
        value: params.row.replicas,
      }),
  },
];
