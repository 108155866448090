import { useQuery } from "@tanstack/react-query";
import { useEffect, useState } from "react";

import {
  GetNotEvictableGraphs,
  GetNotEvictableGraphsQueryKey,
  GetNotEvictableGraphsResponse,
} from "../../../api/fetcher";
import { getEpochStartDate } from "../../../utils/dateAndTimeUtils";
import ComposeChart from "../../ComposeChart/ComposeChart";
import { ChartData, ChartElement, YAxisTickFormatterType } from "../../ComposeChart/utils";
import { TabOptions } from "./utils";

const syncId = "unevictable";

const getInitialState = (daysAgo: TabOptions) => {
  const now = Date.now();
  const daysAgoInt = Number(daysAgo.replace(/\D/g, ""));
  const startDate = getEpochStartDate(daysAgoInt, now);
  return {
    from: startDate,
    to: now,
  };
};

const { queryKey, queryFn } = GetNotEvictableGraphs();

interface Props {
  chartType: GetNotEvictableGraphsQueryKey[];
  title: string;
  elements: ChartElement[];
  yAxisTickFormatterType: YAxisTickFormatterType;
  daysAgo: TabOptions;
}

const UnEvictableChart = ({ chartType, title, elements, yAxisTickFormatterType, daysAgo }: Props) => {
  const [dateRange, setRange] = useState(getInitialState(daysAgo));
  const [graphData, setGraphData] = useState<ChartData>([]);

  const { data, isLoading } = useQuery<GetNotEvictableGraphsResponse, Error>({
    queryKey: [queryKey, chartType, dateRange.from, dateRange.to],
    queryFn: () =>
      queryFn({
        from: dateRange.from,
        to: dateRange.to,
        queryKey: chartType,
      }),
  });

  useEffect(() => {
    setRange(getInitialState(daysAgo));
  }, [daysAgo]);

  useEffect(() => {
    if (data) {
      const tmpGraphData: ChartData =
        data.values
          ?.filter((el) => {
            return !!el.timestamp && el.values;
          })
          ?.map((el) => {
            return {
              timestamp: String(el.timestamp),
              values: el?.values as { [key: string]: number },
            };
          }) ?? [];

      setGraphData(tmpGraphData);
    }
  }, [data, dateRange]);

  return (
    <ComposeChart
      data={graphData}
      title={title}
      isLoading={isLoading}
      elements={elements}
      yAxisTickFormatterType={yAxisTickFormatterType}
      syncId={syncId}
      tooltip={{
        tooltipId: "unevictable-tooltip-id",
      }}
    />
  );
};

export default UnEvictableChart;
