import { useEffect } from "react";
import FreezeTooltipWarning from "../../../../pages/Analytics/AnalyticsV2/Graphs/hooks/FreezeTooltipWarning";
import {
  TooltipTrigger,
  UpdateActiveTooltips,
} from "../../../../pages/Analytics/AnalyticsV2/Graphs/hooks/useFreezeTooltip";
import { FrozenTooltipType } from "../../../../pages/Analytics/AnalyticsV2/Graphs/hooks/utils";
import { customNumberFormatter } from "../../../../utils/formatterUtils";
import { TOOLTIP_WRAPPER_CLASS_NAME } from "../../../../utils/styleUtils";
import ChartTooltipElement from "../../../ChartTooltipElement";
import { CHART_COLORS, ChartDataEntry, ElementNames, ELEMENTS_ORDER } from "./utils";

const ELEMENTS_TOP_DISPLAY = ELEMENTS_ORDER;
ELEMENTS_TOP_DISPLAY.reverse();

interface CustomTooltipProps {
  active?: boolean;
  payload?: { payload: ChartDataEntry }[];
  label?: string;
  tooltipTrigger: TooltipTrigger | undefined;
  updateActiveTooltips: UpdateActiveTooltips | undefined;
}

const CustomTooltip = ({ active, payload, label, tooltipTrigger, updateActiveTooltips }: CustomTooltipProps) => {
  useEffect(() => {
    updateActiveTooltips && updateActiveTooltips(String("un-evictable-bar-chart"), !!active);
  }, [active]);

  if (active && payload && payload.length) {
    return (
      <div className={TOOLTIP_WRAPPER_CLASS_NAME}>
        <p style={{ fontWeight: 600 }} className="text-[10px]">
          {label}
        </p>
        {ELEMENTS_TOP_DISPLAY.map((key) => {
          if (payload[0].payload) {
            return (
              <>
                <ChartTooltipElement
                  color={CHART_COLORS[key as keyof typeof CHART_COLORS]}
                  key={key}
                  value={customNumberFormatter(payload[0].payload[key as keyof ChartDataEntry] as number)}
                  label={ElementNames[key as keyof typeof ElementNames]}
                  enableCopyTextOnClick={key}
                />
              </>
            );
          }
        })}
        <FreezeTooltipWarning
          tooltipTrigger={tooltipTrigger}
          frozenTooltipType={FrozenTooltipType.FrozenAndClickable}
        />
      </div>
    );
  }

  return null;
};

export default CustomTooltip;
