import { Typography } from "@mui/material";
import { StringParam, useQueryParam, withDefault } from "use-query-params";
import Chip, { CHIP_THEME } from "../../../components/Chip";
import DollarIcon from "../../../Icons/DollarIcon";
import FavoriteIcon from "../../../Icons/FavoriteIcon";
import HealthIcon from "../../../Icons/HealthIcon";
import RecommendationIcon from "../../../Icons/RecommendationIcon";
import RightArrowIcon from "../../../Icons/RightArrowIcon";

interface Props {
  onSelect: (group: PreBuiltViewGroups) => void;
}

export enum PreBuiltViewGroups {
  all = "All",
  cost = "Cost",
  performance = "Performance",
  highlight = "Highlight",
  scaleOps = "ScaleOps health",
}

const chipIcon = {
  Cost: <DollarIcon width={14} height={14} />,
  Performance: <RecommendationIcon width={14} height={14} />,
  Highlight: <FavoriteIcon height={14} width={14} />,
  "ScaleOps health": <HealthIcon height={14} width={14} />,
  All: <></>,
};

export const ViewGroups = ({ onSelect }: Props) => {
  const [selected, setIsSelected] = useQueryParam(
    "selectedViewProfile",
    withDefault(StringParam, PreBuiltViewGroups.highlight)
  );

  return (
    <div className="flex gap-2 items-center">
      <Typography variant="body2" className="w-[126px]">
        Select view profile
      </Typography>
      <RightArrowIcon width={14} height={14} />
      <div className="flex flex-row gap-2">
        {[
          PreBuiltViewGroups.highlight,
          PreBuiltViewGroups.cost,
          PreBuiltViewGroups.performance,
          PreBuiltViewGroups.scaleOps,
        ].map((name: PreBuiltViewGroups) => {
          return (
            <button
              className={name == PreBuiltViewGroups.scaleOps ? "w-[160px]" : ""}
              onClick={() => {
                onSelect(name);
                setIsSelected(name);
              }}
            >
              <Chip
                label={
                  <div className={"flex gap-2 items-center justify-between px-2"}>
                    {chipIcon[name]}
                    <Typography variant="caption" fontWeight={400}>
                      {name}
                    </Typography>
                  </div>
                }
                className={
                  selected === name
                    ? "hover:bg-background-darkChipHover cursor-pointer"
                    : "hover:bg-background-chipHover cursor-pointer"
                }
                theme={selected === name ? CHIP_THEME.DARK : CHIP_THEME.DEFAULT}
              />
            </button>
          );
        })}
      </div>
    </div>
  );
};
