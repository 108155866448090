import { Children, cloneElement, isValidElement, ReactNode } from "react";
import Tooltip from "./Tooltip";
import { IsSnapshotServer } from "../utils/FeaturesHelper";
import clsx from "clsx";
import CustomFilterChip, { CustomFilterChipProps } from "./CustomFilterChip";
import MultiSelectByQueryParams, { MultiSelectByQueryParamsProps } from "./MultiSelectByQueryParams";
import { SCALEOPS_COLORS } from "../colors";
import Tab, { TabProps } from "./Tab";
import SnapshotIcon from "../Icons/SnapshotIcon";

type WrappedType = "filter" | "tab";

interface Props {
  children: ReactNode;
  inactive?: boolean;
  wrappedType?: WrappedType;
}

const SnapshotWrapper = ({ children, inactive, wrappedType }: Props) => {
  if (inactive) {
    return <>{children}</>;
  }

  if (!IsSnapshotServer()) {
    return <></>;
  }
  const modifiedChildren = modifyChildren(children, wrappedType);
  return (
    <div className={clsx("relative", { "border-2 border-primary-purpleBlue rounded": !wrappedType })}>
      <Tooltip
        title={
          <>
            This feature is <b>only visible in snapshot mode</b>
          </>
        }
        placement="top-start"
      >
        <div
          className={clsx("absolute z-10 text-primary-purpleBlue flex translate-y-[-50%] items-center", {
            "px-2 mx-1 bg-white": !wrappedType,
          })}
        >
          {!wrappedType && <SnapshotIcon />}
          {wrappedType === "filter" && (
            <SnapshotIcon height={18} width={18} className="-translate-x-0.5 translate-y-0.5, bg-white" />
          )}
          {wrappedType === "tab" && (
            <SnapshotIcon height={18} width={18} className="translate-y-[18px] translate-x-[10px]" />
          )}
          {!wrappedType && <small className="pl-1">Snapshot visibility</small>}
        </div>
      </Tooltip>
      {modifiedChildren}
    </div>
  );
};

const modifyChildren = (children: ReactNode, wrappedType?: WrappedType): ReactNode => {
  let modifiedChildren = children;
  if (wrappedType === "filter") {
    modifiedChildren = Children.map(children, (child) => {
      if (isValidElement(child)) {
        const childProps = child.props as { children: ReactNode };
        child = {
          ...child,
          props: {
            ...childProps,
            children: Children.map(childProps.children, (grandchild) => {
              if (isValidElement(grandchild)) {
                if (grandchild.type === CustomFilterChip) {
                  const props = grandchild.props as CustomFilterChipProps;
                  return cloneElement(grandchild, {
                    ...props,
                    className: "border-primary-purpleBlue",
                  } as CustomFilterChipProps);
                }
                if (grandchild.type === MultiSelectByQueryParams) {
                  const props = grandchild.props as MultiSelectByQueryParamsProps;
                  return cloneElement(grandchild, {
                    ...props,
                    chipSx: { borderColor: SCALEOPS_COLORS.primary.purpleBlue },
                  } as MultiSelectByQueryParamsProps);
                }
              }
              return grandchild;
            }),
          },
        };
      }
      return child;
    });
  }
  if (wrappedType === "tab") {
    modifiedChildren = Children.map(children, (child) => {
      if (isValidElement(child)) {
        if (child.type === Tab) {
          const props = child.props as TabProps;
          return cloneElement(child, {
            ...props,
            className: "border border-b-0 border-primary-purpleBlue hover:border-primary-purpleBlue hover:px-[36px]",
          } as TabProps);
        }
      }
      return child;
    });
  }
  return modifiedChildren;
};

export default SnapshotWrapper;
