import { useQuery, useQueryClient } from "@tanstack/react-query";
import clsx from "clsx";
import { useState } from "react";
import { toast } from "react-toastify";
import { ArrayParam, useQueryParam } from "use-query-params";
import { useMainContext } from "../../MainContext";
import {
  GetBetaFeaturesQuery,
  GetBetaFeaturesResponse,
  GetNodeFilterValues,
  GetNodeGroupInfo,
  GetNodeGroupInfoResponse,
  GetNotEvictableFeatureFlag,
  GetNotEvictableFeatureFlagResponse,
  GetUIFeaturesQuery,
  GetUIFeaturesResponse,
  NodeGroupsScaleDownReason,
} from "../../api/fetcher";
import { FeatureEnabled } from "../../utils/FeaturesHelper";
import { GAPPED_COLUMN } from "../../utils/styleUtils";
import Tab, { TABS_CONTAINER_GAP } from "../Tab";
import { CustomTooltipPayload } from "../WorkloadStatusByNamespace/ResourceOverTime/CustomTooltip";
import ResourceOverTimeContainer from "../WorkloadStatusByNamespace/ResourceOverTime/ResourceOverTimeContainer";
import { ChartComponents } from "../WorkloadStatusByNamespace/ResourceOverTime/utils";
import withDisabled, { TooltipContent } from "../withDisabled";
import CapacityConfiguration from "./CapacityConfiguration";
import ConsolidateNodesContainer from "./Consolidate/ConsolidateNodesContainer";
import { ConvertArrayParamToStringArray } from "./NodeFilterBar";
import { NodeGroupRecommendationView } from "./NodeGroupRecommendation";
import NodeGroupsTab from "./NodeGroupsTab";
import NodePoolsTab from "./NodePoolsTab";
import NodeProvisionersTab from "./NodeProvisionersTab";
import NodesActionsContainer from "./NodesActionsContainer";
import NodesBreakdownTab from "./NodesBreakdownTab";
import NodesCostChartContainer from "./NodesCostChartContainer";
import NodesCostTablesContainer from "./NodesCostTablesContainer";
import NodesTopOverview from "./NodesTopOverview";
import RebalanceContainer from "./Rebalace/RebalanceContainer";
import UnEvictableContainer from "./UnEvictableV/UnEvictableContainer";

const HAS_REBALANCE_CONTAINER = false;
const WrappedConsolidateNodesContainer = withDisabled(ConsolidateNodesContainer);

export enum NodeGroupsTabType {
  NodeGroups = "Node Groups",
  Nodes = "Nodes",
  Provisioners = "Provisioners",
  NodePools = "Node Pools",
}

const convertLabelFilters = (labelFilters: string[]) => {
  const out: { key: string; value: string }[] = [];
  for (const label of labelFilters) {
    const split = label.split("=");
    if (split.length != 2) {
      continue;
    }
    const [key, value] = split;
    out.push({ key: key, value: value });
  }
  return out;
};

export enum NodePoolFilterQueryParams {
  NodePools = "nodePools",
  NodeGroups = "nodeGroups",
  Provisioners = "provisioners",
  AvailabilityZones = "availabilityZones",
  ScaleDownReasons = "scaleDownReasons",
  NodeLabels = "nodeLabels",
  WorkloadLabels = "workloadLabels",
  WorkloadAnnotations = "workloadAnnotations",
  WorkloadNames = "workloadNames",
  ScaleDownReasonsWarn = "scaleDownReasonsWarn",
  ScaleDownReasonsInfo = "scaleDownReasonsInfo",
}

export const getNodeGroupInfoWithFilters = () => {
  const [nodeGroupFilter] = useQueryParam(NodePoolFilterQueryParams.NodeGroups, ArrayParam);
  const [nodePoolFilter] = useQueryParam(NodePoolFilterQueryParams.NodePools, ArrayParam);
  const [provisionerFilter] = useQueryParam(NodePoolFilterQueryParams.Provisioners, ArrayParam);
  const [availabilityZoneFilter] = useQueryParam(NodePoolFilterQueryParams.AvailabilityZones, ArrayParam);
  const [labelFilters] = useQueryParam(NodePoolFilterQueryParams.NodeLabels, ArrayParam);
  const [scaleDownReasonWarnFilter] = useQueryParam(NodePoolFilterQueryParams.ScaleDownReasonsWarn, ArrayParam);
  const [scaleDownReasonInfoFilter] = useQueryParam(NodePoolFilterQueryParams.ScaleDownReasonsInfo, ArrayParam);
  const nodeGroupsInfoQuery = GetNodeGroupInfo();
  // const HAS_NODES_COST_SECTION = true;

  return useQuery<GetNodeGroupInfoResponse, Error>({
    queryKey: [
      nodeGroupsInfoQuery.queryKey,
      nodeGroupFilter,
      nodePoolFilter,
      provisionerFilter,
      availabilityZoneFilter,
      scaleDownReasonWarnFilter,
      scaleDownReasonInfoFilter,
      labelFilters,
    ],
    queryFn: () => {
      return nodeGroupsInfoQuery.queryFn({
        availabilityZones: ConvertArrayParamToStringArray(availabilityZoneFilter),
        nodeGroups: ConvertArrayParamToStringArray(nodeGroupFilter),
        nodePools: ConvertArrayParamToStringArray(nodePoolFilter),
        provisioners: ConvertArrayParamToStringArray(provisionerFilter),
        labels: convertLabelFilters(ConvertArrayParamToStringArray(labelFilters)),
        scaleDownReasonsWarn: ConvertArrayParamToStringArray(scaleDownReasonWarnFilter).map((entity) => ({
          reason: entity,
        })) as NodeGroupsScaleDownReason[],
        scaleDownReasonsInfo: ConvertArrayParamToStringArray(scaleDownReasonInfoFilter).map((entity) => ({
          reason: entity,
        })) as NodeGroupsScaleDownReason[],
      });
    },
    refetchInterval: 1000 * 60 * 5,
  });
};

const { queryFn: queryFnFeatureFlag, queryKey: queryKeyFeatureFlag } = GetNotEvictableFeatureFlag();

const nodeFiltersQuery = GetNodeFilterValues();

export default function NodeGroups() {
  const queryClient = useQueryClient();

  const [selectedTab, setSelectedTab] = useState("Nodes");
  const { didClusterChanged } = useMainContext();
  const uiFeaturesQuery = GetUIFeaturesQuery();

  const { data: uiFeaturesData } = useQuery<GetUIFeaturesResponse, Error>({
    queryKey: [uiFeaturesQuery.queryKey],
    queryFn: uiFeaturesQuery.queryFn,
  });

  const { data: dataNotEvictableFeatureFlag } = useQuery<GetNotEvictableFeatureFlagResponse, Error>({
    queryKey: [queryKeyFeatureFlag],
    queryFn: queryFnFeatureFlag,
  });

  const betaFeaturesQuery = GetBetaFeaturesQuery();
  const { data: betaFeaturesData } = useQuery<GetBetaFeaturesResponse, Error>({
    queryKey: [betaFeaturesQuery.queryKey],
    queryFn: betaFeaturesQuery.queryFn,
  });

  const hasUnEvictableContainer = !!dataNotEvictableFeatureFlag?.featureEnable;

  const CHART_COMPONENTS =
    FeatureEnabled("ShowNodeSimulation") && FeatureEnabled("DemoVersion")
      ? [
          ChartComponents.Request,
          ChartComponents.Allocatable,
          ChartComponents.RecommendedAllocatable,
          ChartComponents.Waste,
          ChartComponents.WasteFromAllocatableToRecommendedAllocatable,
        ]
      : [
          ChartComponents.Usage,
          ChartComponents.Request,
          ChartComponents.Waste,
          ChartComponents.Allocatable,
          ChartComponents.WasteFromAllocatableToRequest,
        ];

  const getWasteValue =
    FeatureEnabled("ShowNodeSimulation") && FeatureEnabled("DemoVersion")
      ? (payload: CustomTooltipPayload) => payload.allocatable - payload.recommendedAllocatable
      : (payload: CustomTooltipPayload) => payload.allocatable - payload.request;

  const HAS_NODES_COST_SECTION = FeatureEnabled("ShowNodeSimulation");
  // const HAS_NODES_COST_SECTION = true;

  const { isError, error, data, isLoading, isFetching } = getNodeGroupInfoWithFilters();

  if (error && didClusterChanged) {
    toast.error("Error fetching data for this cluster");
    console.log(error);
  }

  if (isError) {
    toast.error("Failed to get node details");
    return null;
  }

  if (isFetching) {
    queryClient.invalidateQueries([nodeFiltersQuery.queryKey]);
  }

  const isEmpty = (data?.nodeStats || []).length === 0;

  const totalCpuRequests =
    isEmpty || isLoading ? 0 : data.nodeStats.map((r) => r.cpuRequest).reduce((s, r) => s + r, 0);
  const totalCpuUsage = isEmpty || isLoading ? 0 : data.nodeStats.map((r) => r.cpuUsage).reduce((s, r) => s + r, 0);
  const totalCpuAllocated =
    isEmpty || isLoading ? 0 : data.nodeStats.map((r) => r.cpuAllocatable).reduce((s, r) => s + r, 0);
  const totalMemoryRequests =
    isEmpty || isLoading ? 0 : data.nodeStats.map((r) => r.memoryRequest).reduce((s, r) => s + r, 0);
  const totalMemoryUsage =
    isEmpty || isLoading ? 0 : data.nodeStats.map((r) => r.memoryUsage).reduce((s, r) => s + r, 0);
  const totalMemoryAllocated =
    isEmpty || isLoading ? 0 : data.nodeStats.map((r) => r.memoryAllocatable).reduce((s, r) => s + r, 0);
  const totalCost = isEmpty || isLoading ? 0 : Math.floor(data.nodeStats.map((r) => r.cost).reduce((s, r) => r + s, 0));

  const hasNodeGroupsRows = !!(data?.nodeGroups && data?.nodeGroups.length > 0);
  const hasProvisionersRows = !!(data?.provisioners && data?.provisioners?.length > 0);
  const hasNodePoolRows = !!(data?.nodePools && data?.nodePools?.length > 0);
  let content = null;
  switch (true) {
    case selectedTab === NodeGroupsTabType.NodeGroups && hasNodeGroupsRows:
      content = <NodeGroupsTab nodeGroups={data?.nodeGroups || []} isLoading={isLoading} />;
      break;
    case selectedTab === NodeGroupsTabType.Provisioners && hasProvisionersRows:
      content = <NodeProvisionersTab nodeProvisioners={data?.provisioners || []} isLoading={isLoading} />;
      break;
    case selectedTab === NodeGroupsTabType.Nodes:
      content = (
        <NodesBreakdownTab
          isLoading={isLoading}
          nodeStats={data?.nodeStats || []}
          nodeGroups={data?.nodeGroups || []}
        />
      );
      break;
    case selectedTab == NodeGroupsTabType.NodePools && hasNodePoolRows:
      content = <NodePoolsTab nodePools={data?.nodePools || []} isLoading={isLoading} />;
      break;
  }

  return (
    <div>
      <div className={GAPPED_COLUMN}>
        <NodesTopOverview
          cost={totalCost}
          cpuUsage={totalCpuUsage}
          cpuRequests={totalCpuRequests}
          cpuAllocated={totalCpuAllocated}
          memoryUsage={totalMemoryUsage}
          memoryRequests={totalMemoryRequests}
          memoryAllocated={totalMemoryAllocated}
        />
        <div className={GAPPED_COLUMN}>
          <ResourceOverTimeContainer
            showAllocatable={true}
            getWasteValue={getWasteValue}
            includedChartComponents={CHART_COMPONENTS}
            defaultSelectedChartComponents={CHART_COMPONENTS}
            requestHasLightFill={FeatureEnabled("ShowNodeSimulation") && FeatureEnabled("DemoVersion")}
            includeIgnoredNamespaces={true}
            includeAllWorkloads={true}
            nodeView={true}
          />
          {hasUnEvictableContainer && <UnEvictableContainer />}
          <WrappedConsolidateNodesContainer
            disabled={uiFeaturesData?.clusterDoesNotSupportAction}
            disabledTooltipContent={TooltipContent.FeatureNotSupported}
            toolTipMaxWidth={500}
            hasNodeGroups={hasNodeGroupsRows}
            hasNodePools={hasNodePoolRows}
          />
          {HAS_REBALANCE_CONTAINER && <RebalanceContainer />}
          {HAS_NODES_COST_SECTION && (
            <>
              <NodesCostTablesContainer />
              <NodesCostChartContainer />
            </>
          )}
          <div>
            <div className={clsx("flex", TABS_CONTAINER_GAP)}>
              <Tab
                isSelected={selectedTab === NodeGroupsTabType.Nodes}
                onClick={() => {
                  setSelectedTab(NodeGroupsTabType.Nodes);
                }}
                name={NodeGroupsTabType.Nodes}
              />

              {hasNodeGroupsRows && (
                <Tab
                  isSelected={selectedTab === NodeGroupsTabType.NodeGroups}
                  onClick={() => {
                    setSelectedTab(NodeGroupsTabType.NodeGroups);
                  }}
                  name={NodeGroupsTabType.NodeGroups}
                />
              )}
              {hasProvisionersRows && (
                <Tab
                  isSelected={selectedTab === NodeGroupsTabType.Provisioners}
                  onClick={() => {
                    setSelectedTab(NodeGroupsTabType.Provisioners);
                  }}
                  name={NodeGroupsTabType.Provisioners}
                />
              )}
              {hasNodePoolRows && (
                <Tab
                  isSelected={selectedTab === NodeGroupsTabType.NodePools}
                  onClick={() => {
                    setSelectedTab(NodeGroupsTabType.NodePools);
                  }}
                  name={NodeGroupsTabType.NodePools}
                />
              )}
            </div>

            <div className="w-full rounded-bl-lg rounded-br-lg rounded-tr-lg rounded-border flex flex-col gap-4 p-[20px] pb-[38px] bg-white shadow-sm relative">
              {content}
              {FeatureEnabled("ShowNodeRecommendationButton") &&
                FeatureEnabled("DemoVersion") &&
                !FeatureEnabled("ShowNodeSimulation") && <NodeGroupRecommendationView />}
            </div>
          </div>
          <NodesActionsContainer />
          <CapacityConfiguration
            isBeta={betaFeaturesData?.enabled && betaFeaturesData?.betaFeatures?.nodeCapacityConfiguration}
          />
        </div>
      </div>
      {/*{FeatureEnabled("NodeScalingInSights") && <NodeInsights />}*/}
    </div>
  );
}
