import { Typography } from "@mui/material";
import clsx from "clsx";
import InfoIcon from "../Icons/InfoIcon";
import Tooltip from "./Tooltip";

export const TABS_CONTAINER_GAP = "gap-[3px]";
export const TABS_CONTAINER_CLASS_NAME = `flex ${TABS_CONTAINER_GAP} absolute`;

export interface TabProps {
  name: string;
  onClick: () => void;
  isSelected: boolean;
  dataTestId?: string;
  hasDefaultGrayBorders?: boolean;
  hasSelectedUnderline?: boolean;
  disabled?: boolean;
  tooltipContent?: React.ReactNode;
  star?: boolean;
  className?: string;
}

const Tab = ({
  name,
  onClick,
  isSelected,
  dataTestId,
  hasDefaultGrayBorders,
  hasSelectedUnderline,
  disabled,
  tooltipContent,
  star,
  className,
}: TabProps) => {
  return (
    <Tooltip title={tooltipContent} disabled={!tooltipContent} maxWidth={500}>
      <div
        onClick={() => !disabled && onClick()}
        className={clsx(
          "flex justify-center items-center px-[36px] h-9 rounded-tl-lg rounded-tr-lg select-none",
          {
            "hover:bg-[#f4f5f6] hover:border hover:border-border hover:px-[35px] hover:border-b-0":
              !isSelected && !disabled,
            "bg-background-tab": !isSelected,
            "bg-white": isSelected,
            "border border-b-0": isSelected && hasDefaultGrayBorders,
            "opacity-50 cursor-not-allowed": disabled,
            "cursor-pointer": !disabled,
          },
          { [className || ""]: className }
        )}
        aria-selected={isSelected}
        data-testid={dataTestId && `${dataTestId}-wrapper`}
      >
        <Typography variant="body2" data-testid={dataTestId && `${dataTestId}-option`}>
          <div className="flex items-center">
            <span>{name}</span>
            {star && <InfoIcon width={10} height={10} />}
          </div>
          {hasSelectedUnderline && isSelected && (
            <div className="h-[1px] bg-guideline-lightPurple w-[120%] ml-[-10%]" />
          )}
        </Typography>
      </div>
    </Tooltip>
  );
};

export default Tab;
