import clsx from "clsx";

const MAIN_CLASS_NAME = "bg-white text-black hover:bg-guideline-lightGray";
export interface CustomFilterChipProps {
  label: string;
  onClick: () => void;
  marginTop?: string;
  selected: boolean;
  disabled?: boolean;
  className?: string;
}

const CustomFilterChip = ({ label, onClick, marginTop, selected, disabled, className }: CustomFilterChipProps) => (
  <div
    className={clsx(
      "text-[13px] border border-black rounded-full px-[12px] py-[5px] h-8 select-none whitespace-nowrap",
      {
        [`${MAIN_CLASS_NAME} opacity-50 cursor-not-allowed`]: disabled,
        "bg-text-lightBlack text-white cursor-pointer": selected && !disabled,
        [`${MAIN_CLASS_NAME} cursor-pointer`]: !selected && !disabled,
      },
      { [className || ""]: className }
    )}
    onClick={() => {
      if (!disabled) {
        onClick();
      }
    }}
    style={{
      marginTop,
    }}
  >
    {label}
  </div>
);

export default CustomFilterChip;
