import AlertBar, { AlertBarTheme } from "../../../components/AlertBar";
import ThreeDotsAnimation from "../../../components/ThreeDotsAnimation";

const CalculatingHPAAlertBar = () => {
  return (
    <AlertBar
      title={
        <div className="flex items-center justify-start gap-1">
          <span>Calculating HPA</span>
          <div>
            <ThreeDotsAnimation />
          </div>
        </div>
      }
      customDescription={
        <>
          ScaleOps is currently <b>calculating the HPA recommendation</b> for your workload. This process may take a few
          minutes.
        </>
      }
      theme={AlertBarTheme.Default}
    />
  );
};

export default CalculatingHPAAlertBar;
