import { Typography } from "@mui/material";
import clsx from "clsx";
import React from "react";
import { components } from "../../api/schema";
import { SCALEOPS_COLORS } from "../../colors";
import CheeseIcon from "../../Icons/CheeseIcon";
import ExternalLinkIcon from "../../Icons/ExternalLinkIcon";
import ScaleopsIcon from "../../Icons/ScaleopsIcon";
import ThickRightArrow from "../../Icons/ThickRightArrow";
import { GetConfig } from "../../utils/ConfigHelper";
import { documentationUrl } from "../../utils/urlUtils";

const GREEN_BOLD_STYLE = {
  fontWeight: 600,
  color: SCALEOPS_COLORS.main.green,
};

const SCALEOPS_WILL_NEVER_DELETE_MESSAGE = (
  <>
    ScaleOps will <span style={GREEN_BOLD_STYLE}>never actively enforce updates for running pods</span> of this workload
  </>
);

type RowElement = {
  [key: string]: (
    token?: string,
    value?: number | string
  ) => {
    icon: JSX.Element;
    name: string;
    description: JSX.Element;
  };
};

const elements: RowElement = {
  uponPodCreationStrategy: (token) => ({
    icon: <CheeseIcon />,
    name: "Upon pod creation strategy",
    description: SCALEOPS_WILL_NEVER_DELETE_MESSAGE,
    link: documentationUrl({
      path: "guides/policies",
      subPath: "update-strategy",
      token,
    }),
  }),
  onDeleteUpdateStrategy: (token, workloadStrategyType) => ({
    icon: <CheeseIcon />,
    name: `"onDelete" Update Strategy`,
    description: (
      <>
        {workloadStrategyType} workload has an <span style={GREEN_BOLD_STYLE}>on delete strategy</span>
      </>
    ),
    link: documentationUrl({
      path: "guides/policies",
      subPath: "ondelete-update-strategy",
      token,
    }),
  }),
  pdb: (token, replicas) => ({
    icon: <CheeseIcon />,
    name: "PDB detected",
    description: (
      <>
        a minimum of {replicas} pods <span style={GREEN_BOLD_STYLE}>will be available</span>
      </>
    ),
    link: documentationUrl({
      path: "guides/policies",
      subPath: "workload-availability",
      token,
    }),
  }),
  protectedAnnotation: (token, replicas) => ({
    icon: <CheeseIcon />,
    name: "Protected annotation",
    description: (
      <>
        a minimum of {replicas} pods <span style={GREEN_BOLD_STYLE}>will be available</span>
      </>
    ),
    link: documentationUrl({
      path: "guides/policies",
      subPath: "workload-availability",
      token,
    }),
  }),
  minimumAvailablePods: (token, availableReplicas) => ({
    icon: <CheeseIcon />,
    name: "Minimum available pods",
    description: (
      <>
        {availableReplicas} pod will <span style={GREEN_BOLD_STYLE}>always be available</span> for this workload.
      </>
    ),
    link: documentationUrl({
      path: "guides/policies",
      subPath: "workloads-minimum-replicas-set-to-0",
      token,
    }),
  }),
  hpa: (token: string | undefined) => ({
    icon: <CheeseIcon />,
    name: "Resource based HPA trigger",
    description: (
      <>
        ScaleOps will update <span style={GREEN_BOLD_STYLE}>HPA trigger</span> for this workload.
      </>
    ),
    link: documentationUrl({
      path: "guides/hpa",
      token,
    }),
  }),
  vpa: () => ({
    icon: <CheeseIcon />,
    name: "Active VPA detected",
    description: (
      <>
        ScaleOps will <span style={GREEN_BOLD_STYLE}>disable VPA optimizations</span> for this workload
      </>
    ),
  }),
};
interface StrategyRowProps {
  icon: JSX.Element;
  name: string;
  description: React.ReactNode;
  link?: string;
}

const StrategyRow = ({ icon, name, description, link }: StrategyRowProps) => {
  const iconCopy = React.cloneElement(icon, { className: "text-main-green", height: 20, width: 20 });

  return (
    <Typography variant="caption" className="flex items-center gap-2">
      <div>{iconCopy}</div>
      <a
        style={{ fontWeight: 600 }}
        href={link}
        target="_blank"
        className={clsx("flex items-center gap-1", {
          "text-main-blue hover:underline": !!link,
        })}
        onClick={() => {
          if (!link) return false;
        }}
      >
        {name}
        {link && <ExternalLinkIcon width={12} height={12} />}
      </a>
      <ThickRightArrow />
      <div>{description}</div>
    </Typography>
  );
};

interface AutomationTooltipProps {
  details: components["schemas"]["UtilsRollingStrategyDetails"] | undefined;
  replicas: number | undefined;
  hasHap: boolean;
  hasVpa: boolean;
}

const AutomationTooltip = ({ details, replicas, hasHap, hasVpa }: AutomationTooltipProps) => {
  const config = GetConfig();
  const hasUponCreationStrategy = details?.rolloutPolicyValue === "OnCreate";
  const hasOnDeleteStrategy =
    (details?.workloadStrategyType === "StatefulSet" || details?.workloadStrategyType === "DaemonSet") &&
    details?.workloadStrategy === true;
  const hasPdb = !!details?.pdb && Number(details?.pdb ?? 0) >= Number(details?.minimumReplicas ?? 0);
  const protectedAnnotation = details?.protectedAnnotation;
  const hasMinimumReplicas = !!details?.minimumReplicas;

  return (
    <div>
      <Typography variant="body2" fontWeight={600}>
        Automation strategy
      </Typography>
      <Typography variant="caption" className="flex gap-[4px] items-center">
        <ScaleopsIcon width={10} height={10} />
        <span
          className="text-guideline-darkPurple"
          style={{
            fontWeight: 600,
          }}
        >
          ScaleOps
        </span>
        will automate the workload as follow:
      </Typography>
      <div className="flex flex-col gap-3 mt-3">
        {hasOnDeleteStrategy && <StrategyRow {...elements.onDeleteUpdateStrategy(details?.workloadStrategyType)} />}
        {!hasOnDeleteStrategy && hasUponCreationStrategy && <StrategyRow {...elements.uponPodCreationStrategy()} />}
        {!hasOnDeleteStrategy && !hasUponCreationStrategy && protectedAnnotation && (
          <StrategyRow {...elements.protectedAnnotation(config.token, replicas ?? 0)} />
        )}
        {!hasOnDeleteStrategy && !hasUponCreationStrategy && !protectedAnnotation && hasPdb && (
          <StrategyRow {...elements.pdb(config.token, replicas ?? 0)} />
        )}
        {!hasOnDeleteStrategy && !hasUponCreationStrategy && !protectedAnnotation && !hasPdb && hasMinimumReplicas && (
          <StrategyRow {...elements.minimumAvailablePods(config.token, details?.minimumReplicas ?? 0)} />
        )}
        {hasHap && <StrategyRow {...elements.hpa()} />}
        {hasVpa && <StrategyRow {...elements.vpa()} />}
      </div>
    </div>
  );
};

export default AutomationTooltip;
