import { Typography } from "@mui/material";
import { useQuery } from "@tanstack/react-query";
import { GetAdmissionOptions, GetAdmissionOptionsResponse } from "../../../api/fetcher";
import BinPackingIcon from "../../../Icons/BinPackingIcon";
import InfoIcon from "../../../Icons/InfoIcon";
import useCanUserAutomateCluster from "../../../utils/useCanUserAutomateCluster";
import useIsReadyOnlyFrontEnd from "../../../utils/useIsReadyOnlyFrontEnd";
import ActiveChip, { ActiveChipVariant } from "../../ActiveChip";
import SwitchBox, { THEMES } from "../../SwitchBox";
import Tooltip from "../../Tooltip";
import useWindowSize from "../../useWindowSize";
import TotalAutomatedPieChart from "./TotalAutomatedPieChart";
import { useUpdateAdmissionOptions } from "./useUpdateAdmissionOptions";

const { queryFn: admissionOptionsQueryFn, queryKey: admissionOptionsQueryKey } = GetAdmissionOptions();

interface Props {
  optimizedPodsWithoutOwner: number;
  unOptimizedPodsWithoutOwner: number;
}

const PodsWithUnevictableWorkloads = ({ optimizedPodsWithoutOwner, unOptimizedPodsWithoutOwner }: Props) => {
  const size = useWindowSize();
  const updateAdmissionOptions = useUpdateAdmissionOptions();
  const canUserAutomateCluster = useCanUserAutomateCluster();
  const isReadyOnlyFrontEnd = useIsReadyOnlyFrontEnd();
  const hasNoPermissions = !canUserAutomateCluster || isReadyOnlyFrontEnd;
  const isSwitchDisabled = hasNoPermissions;

  const { data: admissionOptions } = useQuery<GetAdmissionOptionsResponse, Error>({
    queryKey: [admissionOptionsQueryKey],
    queryFn: admissionOptionsQueryFn,
  });

  return (
    <div className="w-full flex flex-col gap-6">
      <div className="flex gap-10 items-center">
        <div className="w-[5.625rem] h-[5.625rem] rounded-full bg-primary-purpleBlue flex justify-center items-center">
          <BinPackingIcon width={65} height={65} className="text-white" />
        </div>
        <div>
          <Tooltip
            title={
              <>
                <b>Pods without an owner</b> are not associated with any workload <br />
                to ensure high availability, and <b>can not be evicted by the cluster autoscaler</b>.
              </>
            }
            className="w-max flex items-center gap-1"
            maxWidth={500}
          >
            <Typography variant="h6" fontWeight={700}>
              Automate pods without owner
            </Typography>
            <InfoIcon width={14} height={14} />
          </Tooltip>
          <Typography variant="body2" className="text-text-lightBlack">
            Optimization will be applied upon pod creation
          </Typography>
        </div>
      </div>
      <div className="grow flex relative w-full justify-center h-[152px]">
        <div className="grow flex flex-col justify-center gap-2.5">
          <div className="flex items-center gap-2">
            <div className="relative">
              <Tooltip title={<>You don't have permission to automate the cluster</>} disabled={!hasNoPermissions}>
                <SwitchBox
                  title="AUTOMATE PODS"
                  handleClick={() => {
                    updateAdmissionOptions.mutate({
                      binPackOwnerlessPods: !admissionOptions?.binPackOwnerlessPods,
                    });
                  }}
                  checked={!!admissionOptions?.binPackOwnerlessPods}
                  theme={THEMES.Purple}
                />
              </Tooltip>
              {!isSwitchDisabled && !!unOptimizedPodsWithoutOwner && (
                <ActiveChip value={unOptimizedPodsWithoutOwner} variant={ActiveChipVariant.Purple} />
              )}
            </div>
          </div>
          <Typography variant="body2" className="text-text-lightBlack w-[200px]">
            Automate <b>unevictable pods</b> by the cluster autoscaler
          </Typography>
        </div>
        <div className="bg-strongBorder h-full w-[1px] mx-12" />
        <div className="grow">
          <TotalAutomatedPieChart
            automatedLabel="optimized"
            unAutomatedLabel="un-optimized"
            subtitle="pods without owner"
            title="Optimized"
            totalAutomated={optimizedPodsWithoutOwner}
            totalUnAutomated={unOptimizedPodsWithoutOwner}
            showAutomatedAndUnAutomatedLabels={!!(size.width && size.width > 1560)}
          />
        </div>
      </div>
    </div>
  );
};

export default PodsWithUnevictableWorkloads;
