import East from "@mui/icons-material/East";
import { CircularProgress, Typography } from "@mui/material";
import { DataGrid, GridColDef, GridRenderCellParams, GridRowParams } from "@mui/x-data-grid";
import { useQuery } from "@tanstack/react-query";
import clsx from "clsx";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { StringParam, useQueryParam } from "use-query-params";
import InfoIcon from "../../Icons/InfoIcon";
import ScaleopsIcon from "../../Icons/ScaleopsIcon";
import UnAutomateIcon from "../../Icons/UnAutomateIcon";
import { useMainContext } from "../../MainContext";
import { getDashboardByNamespace, GetDashboardByNamespaceResponse } from "../../api/fetcher";
import RunningNumberBox from "../../pages/Overview/TopOverviewSection/RunningNumberBox";
import { currencyFormatter } from "../../utils/formatterUtils";
import { GAPPED_COLUMN, getDataGridSx, HEADER_HEIGHT } from "../../utils/styleUtils";
import useGetTimeoutSeconds, { MIN_TIMEOUT_SECOND } from "../../utils/useGetTimeoutSeconds";
import Button from "../Button";
import Dialog from "../Dialog";
import PartialBorders from "../PartialBorders";
import Tooltip from "../Tooltip";
import ExploreEntityTooltip from "../WorkloadStatusByNamespace/ExploreEntityTooltip";
import {
  CURRENT_CLUSTER_URL_QUERY_PARAM,
  SELECTED_WORKLOAD_OVERVIEW_ID_QUERY_PARAM,
} from "../WorkloadStatusByNamespace/overviewUtils";
import useFilterQueryParams from "../WorkloadStatusByNamespace/useFilterQueryParams";
import useHpaOptimizationEnabled from "../WorkloadStatusByNamespace/useHpaOptimizationEnabled";
import ExportCSV, { HAS_EXPORT_TABLE_AS_CSV } from "../exportCSV/ExportCSV";
import ResourcesOverTimeChartsContainer from "./ResourcesOverTimeChartsContainer";
import SummaryReportFilters from "./SummaryReportFilters";
import VsBox from "./VsBox";
import { cpuFormat, getMemoryFormat, WorkloadSummary } from "./utils";

const HAS_TABLE_DESCRIPTION = false;
const HAS_COST_AND_PERFORMANCE_DIFF_LABELS = false;
const HAS_NO_AUTOMATION_DIALOGUE = false;

type CSVExportType = {
  workloadName: string;
  activeSavings: number;
  originalCPURequests: number;
  CPURequest: number;
  cpuDiff: number;
  originalMemoryRequests: number;
  memoryRequest: number;
  memoryDiff: number;
  percentageOfOptimizedReplicas: number;
  id: string;
};

type ReduceData = Record<
  string,
  WorkloadSummary & {
    percentageOfOptimizedReplicas: number;
  }
>;

const getAggregatedClusterData = (data: WorkloadSummary[]) => {
  const reducerData = data?.reduce((acc, curr: WorkloadSummary) => {
    const clusterName = curr.clusterName;

    const properties = Object.keys(curr) as (keyof WorkloadSummary)[];

    properties.forEach((property) => {
      const workloadValue = curr[property] ?? 0;
      const accValue = acc[clusterName]?.[property] ?? 0;
      const newValue = Number(workloadValue) + Number(accValue);

      if (typeof curr[property] === "number" && property !== "percentageOfOptimizedReplicas") {
        acc[clusterName] = {
          ...acc[clusterName],
          [property]: newValue,
        };
      }
    });

    return acc;
  }, {} as ReduceData);

  const output = Object.keys(reducerData ?? {})
    .map((clusterName) => {
      return {
        id: clusterName,
        ...reducerData?.[clusterName],
      };
    })
    .map((entry) => {
      let percentageOfOptimizedReplicas = entry.replicas
        ? Math.round(((entry.managedReplicas ?? 0) / entry.replicas) * 100)
        : 0;
      percentageOfOptimizedReplicas = percentageOfOptimizedReplicas > 100 ? 100 : percentageOfOptimizedReplicas;

      return {
        ...entry,
        percentageOfOptimizedReplicas,
      };
    });

  return output;
};

const getRows = (
  data: WorkloadSummary[] | undefined,
  searchTerm: string | undefined | null,
  isMultiCluster?: boolean
) => {
  let output =
    data
      ?.filter((workload) => {
        if (isMultiCluster) {
          return true;
        }
        const term = searchTerm?.toLowerCase().trim();
        if (!term) {
          return true;
        }
        return workload.workloadName?.includes(term);
      })
      .map((entry) => {
        let percentageOfOptimizedReplicas = entry.replicas
          ? Math.round(((entry.managedReplicas ?? 0) / entry.replicas) * 100)
          : 0;
        percentageOfOptimizedReplicas = percentageOfOptimizedReplicas > 100 ? 100 : percentageOfOptimizedReplicas;

        return {
          ...entry,
          percentageOfOptimizedReplicas,
        };
      }) ?? [];

  output = isMultiCluster ? getAggregatedClusterData(output) : output;

  return output;
};

const getOptimizedReplicasTooltipContent = ({
  replicas,
  optimizedReplicas,
  percentageOfOptimizedReplicas,
}: {
  replicas: number;
  optimizedReplicas: number;
  percentageOfOptimizedReplicas: number;
}) => {
  return (
    <Typography variant="body2" className="flex flex-col gap-2">
      <Typography variant="body2" className="flex gap-1">
        <InfoIcon />
        <b className="text-guideline-darkGreen flex items-center ">Optimization in progress</b>{" "}
      </Typography>
      <Typography variant="caption">
        <p>
          <b className="text-guideline-darkGreen">{percentageOfOptimizedReplicas}%</b> of your running replicas{" "}
          <b className="text-guideline-darkGreen">are optimized</b>.
        </p>
      </Typography>
      <Typography variant="caption">
        Optimized replicas: {optimizedReplicas} / {replicas}
      </Typography>
    </Typography>
  );
};

const WORKLOADS_SUFFIX = (
  <Typography variant="caption" className="flex items-center pl-[4px] text-black">
    workloads
  </Typography>
);

const getColumns = (isMultiCluster: boolean): GridColDef[] => [
  {
    field: isMultiCluster ? "id" : "workloadName",
    headerName: isMultiCluster ? "Cluster Name" : "Workload",
    flex: 3,
    minWidth: 150,
    type: "string",
    disableColumnMenu: true,
    sortable: true,
    renderCell: (params: GridRenderCellParams<string, WorkloadSummary, string>) => {
      const displayValue = isMultiCluster ? params.row.id : params.row.workloadName;

      return isMultiCluster ? (
        <ExploreEntityTooltip entityName={displayValue ?? ""} message={"Explore cluster savings result"} />
      ) : (
        <ExploreEntityTooltip entityName={displayValue ?? ""} entityType={params.row.workloadType ?? ""} />
      );
    },
  },
  {
    field: "activeSavings",
    headerName: "Total Saved",
    flex: 1.5,
    minWidth: 150,
    type: "number",
    align: "center",
    disableColumnMenu: true,
    sortable: true,
    renderCell: (params: GridRenderCellParams<string, WorkloadSummary, string>) => {
      const savings = Math.round(params.row.activeSavings * 100) / 100 ?? 0;
      const savingsStr = currencyFormatter(savings);

      return (
        <Typography
          variant="body2"
          fontWeight={500}
          className={clsx({
            "text-guideline-darkGreen": savings > 0,
          })}
        >
          {savingsStr}
        </Typography>
      );
    },
  },
  {
    field: "originalCPURequests",
    headerName: "CPU Request",
    flex: 1.5,
    minWidth: 150,
    type: "number",
    align: "center",
    disableColumnMenu: true,
    sortable: true,
    renderCell: (params: GridRenderCellParams<string, WorkloadSummary, string>) => {
      const original = cpuFormat(params.row.originalCPURequests);
      const request = cpuFormat(params.row.CPURequest);

      return (
        <Typography variant="body2" fontWeight={500} className="flex items-center justify-center gap-2">
          {original}
          <East style={{ fontSize: "medium" }} />
          {request}
        </Typography>
      );
    },
  },
  {
    field: "cpuDiff",
    headerName: "CPU Saved",
    flex: 1.5,
    minWidth: 150,
    type: "number",
    align: "center",
    disableColumnMenu: true,
    sortable: true,
    renderCell: (params: GridRenderCellParams<string, WorkloadSummary, string>) => {
      const diff = cpuFormat(params.row.cpuDiff) * -1;

      let label = "";
      if (HAS_COST_AND_PERFORMANCE_DIFF_LABELS) {
        switch (true) {
          case diff > 0:
            label = "Performance";
            break;
          case diff < 0:
            label = "Cost";
            break;
          default:
            label = "";
        }
      }

      return (
        <Typography
          variant="body2"
          fontWeight={500}
          className={clsx({
            "text-guideline-darkGreen": diff > 0,
          })}
        >
          {diff} <span style={{ fontSize: 10 }}>{label}</span>
        </Typography>
      );
    },
  },
  {
    field: "originalMemoryRequests",
    headerName: "Memory Request",
    flex: 1.5,
    minWidth: 150,
    type: "number",
    align: "center",
    disableColumnMenu: true,
    sortable: true,
    renderCell: (params: GridRenderCellParams<string, WorkloadSummary, string>) => {
      const original = getMemoryFormat(params.row.originalMemoryRequests);
      const request = getMemoryFormat(params.row.memoryRequest);

      return (
        <Typography variant="body2" fontWeight={500} className="flex items-center justify-center gap-2">
          {original[0] ?? 0} {original[1] ?? ""}
          <East style={{ fontSize: "medium" }} />
          {request[0] ?? 0} {request[1] ?? ""}
        </Typography>
      );
    },
  },
  {
    field: "memoryDiff",
    headerName: "Memory Saved",
    flex: 1.5,
    minWidth: 150,
    type: "number",
    align: "center",
    disableColumnMenu: true,
    sortable: true,
    renderCell: (params: GridRenderCellParams<string, WorkloadSummary, string>) => {
      const diffValue = getMemoryFormat(params.row.memoryDiff)[0] * -1 ?? 0;
      const diffSuffix = getMemoryFormat(params.row.memoryDiff)[1] ?? "";

      let label = "";
      if (HAS_COST_AND_PERFORMANCE_DIFF_LABELS) {
        switch (true) {
          case diffValue > 0:
            label = "Performance";
            break;
          case diffValue < 0:
            label = "Cost";
            break;
          default:
            label = "";
        }
      }

      return (
        <Typography
          variant="body2"
          fontWeight={500}
          className={clsx({
            "text-guideline-darkGreen": diffValue > 0,
          })}
        >
          {diffValue} {diffSuffix} <span style={{ fontSize: 10 }}>{label}</span>
        </Typography>
      );
    },
  },
  {
    field: "percentageOfOptimizedReplicas",
    headerName: "Optimized Replicas (%)",
    hide: isMultiCluster,
    width: 150,
    type: "number",
    align: "center",
    disableColumnMenu: true,
    sortable: true,
    renderCell: (
      params: GridRenderCellParams<string, WorkloadSummary & { percentageOfOptimizedReplicas: number }, string>
    ) => {
      const percentageOfOptimizedReplicas = params.row.percentageOfOptimizedReplicas ?? 0;

      const isTooltipDisabled = percentageOfOptimizedReplicas === 100 || percentageOfOptimizedReplicas === 0;

      return (
        <Tooltip
          title={getOptimizedReplicasTooltipContent({
            replicas: params.row.replicas,
            optimizedReplicas: params.row.managedReplicas,
            percentageOfOptimizedReplicas,
          })}
          disabled={isTooltipDisabled}
          maxWidth={400}
        >
          <Typography variant="body2" fontWeight={500} className="flex items-start justify-center">
            {percentageOfOptimizedReplicas}%{!isTooltipDisabled && <InfoIcon width={10} height={10} />}
          </Typography>
        </Tooltip>
      );
    },
  },
];

interface SummaryReportProps {
  isMultiCluster?: boolean;
}

const SummaryReport = ({ isMultiCluster }: SummaryReportProps) => {
  const { didClusterChanged } = useMainContext();
  const dashboardByNamespace = getDashboardByNamespace();

  const navigate = useNavigate();
  const filters = useFilterQueryParams();

  const [rows, setRows] = useState<WorkloadSummary[]>([]);
  // const [isQueryEnabled, setIsQueryEnabled] = useState(true);
  const [timeoutSeconds, setTimeoutSeconds] = useState<number | undefined>(MIN_TIMEOUT_SECOND);
  const [parsedData, setParsedData] = useState<WorkloadSummary[] | undefined>(undefined);
  const [summary, setSummary] = useState<WorkloadSummary | undefined>(undefined);
  const [performanceOptimized, setPerformanceOptimized] = useState<number>(0);
  const [costOptimized, setCostOptimized] = useState<number>(0);
  const [automatedPercentage, setAutomatedPercentage] = useState<number>(0);
  const [searchTerm] = useQueryParam("setSummarySearchTerm", StringParam);

  const hpaOptimizationEnabled = useHpaOptimizationEnabled();
  const { data, isLoading, isError, error } = useQuery<GetDashboardByNamespaceResponse, Error>({
    queryKey: [dashboardByNamespace.queryKey, filters, isMultiCluster ? "multiCluster" : undefined],
    queryFn: () =>
      dashboardByNamespace.queryFn({
        ...filters,
        multiCluster: isMultiCluster,
        timeoutSeconds: timeoutSeconds,
      }),
    // enabled: isQueryEnabled,
    keepPreviousData: true,
    refetchInterval: timeoutSeconds ? timeoutSeconds * 1000 : 60 * 5 * 1000,
  });

  const timeoutSecondsValue = useGetTimeoutSeconds({ data, isError, isDisabled: !isMultiCluster });

  useEffect(() => {
    setTimeoutSeconds(timeoutSecondsValue);
  }, [timeoutSecondsValue]);

  if (error && didClusterChanged) {
    toast.error("Error fetching data for this cluster");
    console.log(error);
  }

  // useEffect(() => {
  //     if (isMultiCluster && !!data) {
  //         setIsQueryEnabled(false);
  //     }
  // }, [data]);
  //
  // useEffect(() => {
  //     if (isLoading || isError) {
  //         setIsQueryEnabled( true);
  //     }
  // }, [isMultiCluster, filters, isLoading, isError]);

  useEffect(() => {
    const clientWorkloads = data?.workloads?.filter((wl) => wl.namespace !== "scaleops-system") ?? [];
    const clientAutomatedWorkloads = clientWorkloads.filter((wl) => wl.auto);
    const parsedDataValues: WorkloadSummary[] | undefined = clientAutomatedWorkloads
      .filter(
        (wl) => (!!wl.managedReplicas && wl.managedReplicas > 0) || (!!wl.optimizedReplicas && wl.optimizedReplicas > 0)
      )
      .map((workload, index) => {
        const workloadName = workload.workloadName.replace(/^scaleops-rollout-/, "");
        const replicas = workload.replicas ?? 0;
        const managedReplicas = workload.managedReplicas ?? workload.optimizedReplicas ?? 0;
        const runningReplicas = workload.runningReplicas ?? 0;
        const originalCPURequests = workload.ownerCpuRequests * managedReplicas ?? 0;
        // We want to calculate the CPU actual requests only for optimized replicas
        let CPURequest = workload.aggregatedCpuRequests - (replicas - managedReplicas) * workload.ownerCpuRequests;
        if (replicas < managedReplicas || CPURequest === undefined || CPURequest < 0) {
          // Unexpected case, we should not have negative CPU requests
          CPURequest = workload.cpuRecommended * managedReplicas ?? 0;
        }
        const cpuDiff = CPURequest - originalCPURequests;
        const originalMemoryRequests = workload.ownerMemoryRequests * managedReplicas ?? 0;
        // We want to calculate the Memory actual requests only for optimized replicas
        let memoryRequest =
          workload.aggregatedMemRequests - (replicas - managedReplicas) * workload.ownerMemoryRequests;
        if (replicas < managedReplicas || memoryRequest === undefined || memoryRequest < 0) {
          // Unexpected case, we should not have negative memory requests
          memoryRequest = workload.memRecommended * managedReplicas ?? 0;
        }
        const memoryDiff = memoryRequest - originalMemoryRequests;
        const activeSavings = workload.activeSavings ?? 0;
        const clusterName = workload.clusterName ?? "";

        const originalCPURequestsWithReplicas =
          hpaOptimizationEnabled && workload.originMinReplicas && replicas < workload.originMinReplicas
            ? originalCPURequests + workload.ownerCpuRequests * (workload.originMinReplicas - replicas)
            : originalCPURequests;
        const originalMemoryRequestsWithReplicas =
          hpaOptimizationEnabled && workload.originMinReplicas && replicas < workload.originMinReplicas
            ? originalMemoryRequests + workload.ownerMemoryRequests * (workload.originMinReplicas - replicas)
            : originalMemoryRequests;
        const cpuDiffWithReplicas = CPURequest - originalCPURequestsWithReplicas;
        const memoryDiffWithReplicas = memoryRequest - originalMemoryRequestsWithReplicas;
        const overallActiveSavings = workload.overallActiveSavings ?? activeSavings;

        return {
          workloadName: `${workload.namespace}/${workloadName}`,
          workloadType: workload.type,
          id: `${workloadName}-${index}`,
          originalId: workload.id,
          replicas,
          managedReplicas,
          optimizedReplicas: managedReplicas,
          runningReplicas,
          originalCPURequests: originalCPURequestsWithReplicas,
          CPURequest,
          cpuDiff: hpaOptimizationEnabled ? cpuDiffWithReplicas : cpuDiff,
          originalMemoryRequests: originalMemoryRequestsWithReplicas,
          memoryRequest,
          memoryDiff: hpaOptimizationEnabled ? memoryDiffWithReplicas : memoryDiff,
          activeSavings: overallActiveSavings,
          clusterName,
        };
      });

    const cpuPerformanceOptimized = parsedDataValues?.filter((workload) => workload.cpuDiff > 0).length ?? 0;
    const memoryPerformanceOptimized = parsedDataValues?.filter((workload) => workload.memoryDiff > 0).length ?? 0;
    setPerformanceOptimized(cpuPerformanceOptimized + memoryPerformanceOptimized);

    const cpuCostOptimizedValue =
      parsedDataValues?.filter((workload) => workload.cpuDiff < 0 || workload.memoryDiff < 0).length ?? 0;
    setCostOptimized(cpuCostOptimizedValue);

    const totalNumberOfWorkloads = clientWorkloads?.length ?? 0;
    const numberOfAutomatedWorkloads = clientAutomatedWorkloads.length;
    const automatedPercentageValue = Math.round((numberOfAutomatedWorkloads / (totalNumberOfWorkloads || 1)) * 100);
    setAutomatedPercentage(automatedPercentageValue);

    const summaryValues: WorkloadSummary | undefined = parsedDataValues?.reduce(
      (acc, curr) => {
        return {
          ...acc,
          replicas: acc.replicas + curr.replicas,
          managedReplicas: acc.managedReplicas + curr.managedReplicas,
          optimizedReplicas: acc.optimizedReplicas + curr.optimizedReplicas,
          runningReplicas: acc.runningReplicas + curr.runningReplicas,
          originalCPURequests: acc.originalCPURequests + curr.originalCPURequests,
          CPURequest: acc.CPURequest + curr.CPURequest,
          originalMemoryRequests: acc.originalMemoryRequests + curr.originalMemoryRequests,
          memoryRequest: acc.memoryRequest + curr.memoryRequest,
          activeSavings: acc.activeSavings + curr.activeSavings,
          clusterName: curr.clusterName,
        };
      },
      {
        id: 1,
        clusterName: "",
        replicas: 0,
        managedReplicas: 0,
        optimizedReplicas: 0,
        runningReplicas: 0,
        originalCPURequests: 0,
        CPURequest: 0,
        originalMemoryRequests: 0,
        memoryRequest: 0,
        activeSavings: 0,
        cpuDiff: 0,
        memoryDiff: 0,
      }
    );

    if (summaryValues) {
      summaryValues.cpuDiff = summaryValues.CPURequest - summaryValues.originalCPURequests;
      summaryValues.memoryDiff = summaryValues.memoryRequest - summaryValues.originalMemoryRequests;
    }

    setParsedData(parsedDataValues);
    setSummary(summaryValues);
  }, [data, searchTerm, filters]);

  useEffect(() => {
    setRows(getRows(parsedData, searchTerm, !!isMultiCluster));
  }, [parsedData, searchTerm, isMultiCluster]);

  if (isLoading && !data) {
    return (
      <div className="bg-white rounded-lg flex-grow flex items-center justify-center min-w-[700px] h-[300px] w-[100%] left-[0px] top-[0px]">
        <CircularProgress />
      </div>
    );
  }

  const memoryRequestBeforeValueSuffix = getMemoryFormat(summary?.originalMemoryRequests)[1];
  const memoryRequestAfterValueSuffix = getMemoryFormat(summary?.memoryRequest)[1];

  let activeSavings = Math.round(summary?.activeSavings ?? 0);
  activeSavings = activeSavings < 0 ? 0 : activeSavings;

  return (
    <div className={GAPPED_COLUMN}>
      <Dialog
        isOpen={HAS_NO_AUTOMATION_DIALOGUE && !!(parsedData && parsedData.length === 0)}
        hasTopTitle={false}
        fullWidth={false}
      >
        <div className="flex flex-col gap-4 items-center justify-center p-8">
          <Typography variant="h6">
            None of your workloads are <span className="text-main-green">automated</span>
          </Typography>
          <UnAutomateIcon width={100} height={100} />
          <Typography variant="body1">Empower your Kubernetes clusters with ScaleOps.</Typography>
          <Button
            label="Go to Overview"
            onClick={() => {
              navigate("/");
            }}
          />
        </div>
      </Dialog>
      <div className="flex gap-3 border border-border rounded-lg p-5">
        <div className="flex items-center">
          <ScaleopsIcon width={60} height={60} />
        </div>
        <div className="grow">
          <Typography variant="h6" fontWeight="bold">
            Automated Workloads Summary
          </Typography>
          <Typography variant="body2">
            <b>Explore how ScaleOps has optimized your workloads.</b>
          </Typography>
        </div>
      </div>
      <div className="flex gap-2 items-center border border-border rounded-lg py-5">
        <VsBox
          title="CPU request"
          original={cpuFormat(summary?.originalCPURequests)}
          current={cpuFormat(summary?.CPURequest)}
          shouldFormatNumbers
        />
        <VsBox
          title="Memory request"
          original={summary?.originalMemoryRequests ?? 0}
          current={summary?.memoryRequest ?? 0}
          beforeValueSuffix={memoryRequestBeforeValueSuffix}
          afterValueSuffix={memoryRequestAfterValueSuffix}
          valueParser={(value: number) => {
            return getMemoryFormat(value)[0];
          }}
        />
        <div className={clsx("mt-[11px] grow")}>
          <PartialBorders>
            <div className="flex flex-col gap-4 justify-center items-center">
              <RunningNumberBox
                value={activeSavings}
                valueDataTestId="top-overview-current-savings-value"
                title={
                  <>
                    Active savings <span className="text-[12px] text-strongBorder">(monthly)</span>
                  </>
                }
                numberVariant="h4"
                prefix="$"
                numberClassName={activeSavings > 0 ? "text-main-green" : "text-black"}
              />
              <div className="bg-strongBorder h-[1px] w-[80%]" />
              <RunningNumberBox
                value={automatedPercentage}
                title="Automated"
                valueDataTestId="top-overview-monthly-cost-value"
                numberVariant="h4"
                numberClassName="text-black"
                suffix="%"
              />
            </div>
          </PartialBorders>
        </div>
        <div className="bg-strongBorder h-[133px] w-[1px]" />
        <div className={clsx("mt-[11px] grow relative")}>
          <PartialBorders right={true}>
            <div className="flex flex-col gap-4 justify-center items-center">
              <RunningNumberBox
                value={performanceOptimized}
                title={
                  <Tooltip
                    title={
                      <>
                        <b>ScaleOps</b> scaled up requests for these workloads, <br /> adapting to higher usage demands
                      </>
                    }
                    className="w-max flex items-center"
                    maxWidth={450}
                  >
                    <span>Performance optimized</span>
                    <InfoIcon width={11} height={11} />
                  </Tooltip>
                }
                valueDataTestId="top-overview-monthly-cost-value"
                numberVariant="h4"
                numberClassName="text-main-green"
                suffix={WORKLOADS_SUFFIX}
              />
              <div className="bg-strongBorder h-[1px] w-[80%]" />
              <RunningNumberBox
                value={costOptimized}
                title="Cost optimized"
                valueDataTestId="top-overview-monthly-cost-value"
                numberVariant="h4"
                numberClassName="text-black"
                suffix={WORKLOADS_SUFFIX}
              />
            </div>
          </PartialBorders>
        </div>
      </div>
      <ResourcesOverTimeChartsContainer hasSavingsDiff isMultiCluster={isMultiCluster} />
      {HAS_TABLE_DESCRIPTION && (
        <div>
          <Typography variant="h6">Workload breakdown</Typography>
          <Typography className="max-w-[800px]" sx={{ fontSize: 12 }}>
            Explore the details of each workload and compare the CPU and memory requests{" "}
            <b>before and after using ScaleOps</b>.
          </Typography>
        </div>
      )}
      <div className="flex w-full items-center">
        <div className="grow">
          <SummaryReportFilters isMultiCluster={isMultiCluster} />
        </div>
      </div>
      <DataGrid
        pagination={true}
        headerHeight={HEADER_HEIGHT}
        rowHeight={65}
        columns={getColumns(!!isMultiCluster)}
        rows={rows}
        sx={{
          ...getDataGridSx(),
        }}
        initialState={{
          pagination: {
            pageSize: 25,
          },
          sorting: {
            sortModel: [
              {
                field: "activeSavings",
                sort: "desc",
              },
            ],
          },
        }}
        style={{ borderRadius: "5px" }}
        experimentalFeatures={{ newEditingApi: true }}
        autoHeight={true}
        disableSelectionOnClick
        onRowClick={(params: GridRowParams<WorkloadSummary>) => {
          if (!isMultiCluster && params?.row.originalId) {
            navigate(`/?${SELECTED_WORKLOAD_OVERVIEW_ID_QUERY_PARAM}=${params.row.originalId}`);
          } else if (params?.row.id) {
            navigate(`/reports?${CURRENT_CLUSTER_URL_QUERY_PARAM}=${params.row.id ?? ""}`);
          }
        }}
        getCellClassName={() => "cursor-pointer"}
      />
      {HAS_EXPORT_TABLE_AS_CSV && (
        <div className="mt-[-68px] ml-[10px] z-50 relative w-fit">
          <ExportCSV<CSVExportType>
            filename="savings_result.csv"
            columns={
              [
                isMultiCluster ? "id" : "workloadName",
                "activeSavings",
                "originalCPURequests",
                "CPURequest",
                "cpuDiff",
                "originalMemoryRequests",
                "memoryRequest",
                "memoryDiff",
                ...(!isMultiCluster ? ["percentageOfOptimizedReplicas"] : []),
              ] as (keyof CSVExportType)[]
            }
            columnsToRound={["activeSavings"]}
            data={
              rows.map((row) => {
                return {
                  ...row,
                  cpuDiff: row.cpuDiff * -1,
                  memoryDiff: row.memoryDiff * -1,
                  id: isMultiCluster ? row.id : row.workloadName,
                };
              }) as CSVExportType[]
            }
            columnsToSum={[
              "activeSavings",
              "originalCPURequests",
              "CPURequest",
              "cpuDiff",
              "originalMemoryRequests",
              "memoryRequest",
              "memoryDiff",
            ]}
            customColumnNames={{
              workloadName: isMultiCluster ? "Cluster Name" : "Workload",
              id: isMultiCluster ? "Cluster Name" : "id",
              activeSavings: "Total Saved",
              originalCPURequests: "Original CPU Request",
              CPURequest: "CPU Request",
              cpuDiff: "CPU Saved",
              originalMemoryRequests: "Original Memory Request",
              memoryRequest: "Memory Request",
              memoryDiff: "Memory Saved",
              percentageOfOptimizedReplicas: "Optimized Replicas (%)",
            }}
          />
        </div>
      )}
    </div>
  );
};

export default SummaryReport;
